// *******************************************************
// Storefront
// *******************************************************
import axios from "axios";
import {useUserStore} from "@/store/user"
import {useIndexStore} from "@/store/index"
import {useAxiosFormStore} from "@/store/axios"
const sendInfoWithService = () => {
    useIndexStore().fbqSendEvents("location");

    var timezonePromise = new Promise(function (resolve) {
    getTimeZoneForStore(useUserStore().form.lat, useUserStore().form.lng, resolve);
    });

    Promise.all([timezonePromise]).then(function (valuesArray) {
    useUserStore().form.tzone = valuesArray[0].timeZoneId;
        if(useIndexStore().newStoreApi){
            createStoreDataWithFullData(useIndexStore().apiResult.data);
        } else {
            createStoreData(useIndexStore().apiResult.data);
        }
    });
};
const getTimeZoneForStore = (lat, lng, callback) => {
axios
    .post(process.env.VUE_APP_RESTURL + "services/apexrest/ServiceProvidedSearch", {
    callType: "timezone",
    timezoneObject: { lat: lat, lng: lng },
    })
    .then(function (response) {
    if (response.status === 200) {
        callback(JSON.parse(response.data));
    } else {
        useIndexStore().loading = false;
        callback(response.status);
    }
    })
    .catch(function (error) {
        useIndexStore().loading = false;
    console.log(error);
    callback(error);
    });
};
const createStoreDataWithFullData =(data) => {
    let storeFrontStore = data;
    let storeFrontUser = [];
    storeFrontUser.push({
        fname: useUserStore().form.firstName,
        lname: useUserStore().form.lastName,
        email: useUserStore().form.email?.toLowerCase(),
        phone: useIndexStore().formatPhone(useUserStore().form.phone),
        streetNumber: useUserStore().form.streetNumber,
        route: useUserStore().form.route,
        saddress: useUserStore().form.street,
        city: useUserStore().form.city,
        state: useUserStore().form.state,
        zip: useUserStore().form.zip,
        country: useUserStore().form.country,
        lat: useUserStore().form.lat,
        lng: useUserStore().form.lng,
        line: useIndexStore().getRealNameFromPackages(useIndexStore().getLeadTypeFromServiceType(useUserStore().form.serviceType,useUserStore().form.categoryType)),
        tzone: useUserStore().form.tzone,
        website: useIndexStore().apiResult.storeFrontURL,
    });
    useIndexStore().storefrontResult.store = storeFrontStore;
    useIndexStore().storefrontResult.user = storeFrontUser;
    sendDataToC2O();
};
const createStoreData = (data) => {
let packages = data.ServicePackges;
let qty = packages.length - 1;
let storeFrontStore = [];
do {
    storeFrontStore.push({
    wcnLeadID: data.WCNLeadID,
    district: data.District,
    districtDetail: data.DistrictDetail,
    C2OSource: data.C2OSource,
    StoreFrontSetting: data.StoreFrontSetting,
    UOM: packages[qty].UOM,
    lineOfBusiness: packages[qty].LineOfBusiness,
    amount: packages[qty].data.amount,
    billArea: packages[qty].data.billArea,
    billed: packages[qty].data.billed,
    billAreaDefaultsId: packages[qty].data.billAreaDefaultsId,
    // containerId: packages[qty].data.containerId,
    containerType: packages[qty].data.lobDescription,
    description: packages[qty].data.description,
    details: packages[qty].data.details,
    geofenceId: packages[qty].data.geofenceId,
    hasTaxes: packages[qty].data.hasTaxes,
    id: packages[qty].data.id,
    lineItems: packages[qty].data.lineItems,
    masterPackageId: packages[qty].data.masterPackageId,
    message: packages[qty].data.message,
    name: packages[qty].data.name,
    priority: packages[qty].data.priority,
    showPricing: packages[qty].data.showPricing,
    stripeFee: packages[qty].data.stripeFee,
    lobDescription: packages[qty].data.lobDescription,
    lobId: packages[qty].data.lobId,
    monthly: packages[qty].data.monthly,
    oneTimeCharges: packages[qty].data.oneTimeCharges,
    perCycle: packages[qty].data.perCycle,
    Images:
        packages[qty].imageLinks.length > 0
        ? packages[qty].imageLinks
        : [
            "https://cdn.wasteconnections.com/images/Containers/standard/NoImage/no-image.jpg",
            ],
    });
    qty--;
} while (qty >= 0);
let storeFrontUser = [];
storeFrontUser.push({
    fname: useUserStore().form.firstName,
    lname: useUserStore().form.lastName,
    email: useUserStore().form.email?.toLowerCase(),
    phone: useIndexStore().formatPhone(useUserStore().form.phone),
    streetNumber: useUserStore().form.streetNumber,
    route: useUserStore().form.route,
    saddress: useUserStore().form.street,
    city: useUserStore().form.city,
    state: useUserStore().form.state,
    zip: useUserStore().form.zip,
    country: useUserStore().form.country,
    lat: useUserStore().form.lat,
    lng: useUserStore().form.lng,
    line: '',
    tzone: useUserStore().form.tzone,
    website: useIndexStore().apiResult.storeFrontURL,
});
useIndexStore().storefrontResult.store = storeFrontStore;
useIndexStore().storefrontResult.user = storeFrontUser;

    // If it's the Wichita Hauling store, 30% chance (AB testing) to use the redirectV2
    const isTrue  = true;
    if(isTrue){
        redirectToStorefrontV2();
    }
    else{
        redirectToStorefrontV1();
    }
};
const redirectToStorefrontV1 = async (storeFrontURL) => {
    const lead = await redirectToStoreFrontLead();
    if (lead && useIndexStore().apiResult.storeFrontURL) {
        document.forms[0].action = storeFrontURL ? storeFrontURL : useIndexStore().apiResult.storeFrontURL;
        document.forms[0].method = "POST";

        var theInput0 = document.createElement("textarea");
        theInput0.name = "StoreFrontStore";
        theInput0.value = encodeURI(JSON.stringify(useIndexStore().storefrontResult.store));
        theInput0.classList.add("d-none");
        document.forms[0].appendChild(theInput0);

        var theInput1 = document.createElement("textarea");
        theInput1.name = "StoreFrontUser";
        theInput1.value = encodeURI(JSON.stringify(useIndexStore().storefrontResult.user));
        theInput1.classList.add("d-none");
        document.forms[0].appendChild(theInput1);

        var theInput2 = document.createElement("input");
        theInput2.name = "lineGroup";
        theInput2.value = useUserStore().form.serviceType;
        theInput2.setAttribute("type", "hidden");
        document.forms[0].appendChild(theInput2);

        if (window.google_tag_manager && typeof window.dataLayer !== "undefined") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "eventTracking",
                action: "Redirect to Storefront",
                event_category: process.env.VUE_APP_GTAG_LEAD_CATEGORY,
                event_label: useIndexStore().apiResult.districtID,
                value: 0,
                lead_type: useIndexStore().getLeadTypeFromServiceType(useIndexStore().form.serviceType,useIndexStore().form.categoryType),
                lead_category: useIndexStore().form.categoryType,
                lead_district: useIndexStore().apiResult.districtID,
                eventCallback: function () {
                    document.forms[0].submit();
                },
                eventTimeout: 2000,
            });
            setTimeout(function() {
                document.forms[0].submit();
            }, 2000);
        } else {
            document.forms[0].submit();
        }
    }
};
const redirectToStorefrontV2 = async (storeFrontURL) => {
    const lead = await redirectToStoreFrontLead();
    const indexStore = useIndexStore();
    const userData = indexStore.storefrontResult.user;
    const apiResult = indexStore.apiResult;

    if (!lead || !(storeFrontURL || apiResult.storeFrontURL)) {
        console.error("Error: lead not found or storeFrontURL not found.");
        return;
    }

    const user = {
        firstName: userData[0].fname || '',
        lastName: userData[0].lname || '',
        address: {
            streetNumber: userData[0].streetNumber || '',
            streetName: userData[0].route || '',
            city: userData[0].city || '',
            state: userData[0].state || '',
            zipCode: userData[0].zip || '',
            country: userData[0].country || '',
            lat: userData[0].lat || null,
            lng: userData[0].lng || null,
        },
        billingAddress: {
            streetNumber: userData[0].streetNumber || '',
            streetName: userData[0].route || '',
            city: userData[0].city || '',
            state: userData[0].state || '',
            zipCode: userData[0].zip || '',
            country: userData[0].country || '',
            lat: userData[0].lat || null,
            lng: userData[0].lng || null,
        },
        email: userData[0].email?.toLowerCase() || '',
        phoneNumber: userData[0].phone || '',
        serviceType: userData[0].line || null,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || '',
        websiteUrl: userData[0].website || '',
        externalLeadId: userData[1].quoteid || '',
    };

    try {
        const response = await axios.post(
            `${process.env.VUE_APP_FB_FORM_STORE}handleFormSubmission`,
            { user }
        );

        if (response.status === 200 && response.data.uid) {
            const uid = response.data.uid;
            const dataLayerEvent = {
                event: 'eventTracking',
                action: 'Redirect to Storefront',
                event_category: process.env.VUE_APP_GTAG_LEAD_CATEGORY,
                event_label: apiResult.districtID,
                value: 0,
                lead_type: indexStore.getLeadTypeFromServiceType(
                    indexStore.form.serviceType,
                    indexStore.form.categoryType
                ),
                lead_category: indexStore.form.categoryType,
                lead_district: apiResult.districtID,
                eventCallback: () => (window.location.href = `${storeFrontURL || process.env.VUE_APP_FB_STORE_DOMAIN || apiResult.storeFrontURL}?userId=${uid}`),
                eventTimeout: 2000,
            };

            if (window.google_tag_manager && window.dataLayer) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(dataLayerEvent);
                setTimeout(() => (window.location.href = `${storeFrontURL || process.env.VUE_APP_FB_STORE_DOMAIN || apiResult.storeFrontURL}?userId=${uid}`), 2000);
            } else {
                window.location.href = `${storeFrontURL || process.env.VUE_APP_FB_STORE_DOMAIN || apiResult.storeFrontURL}?userId=${uid}`;
            }
        } else {
            console.error('Submission failed:', response.data.error || 'Unknown error');
        }
    } catch (error) {
        console.error('Submission failed:', error);
    }
};
const sendDataToC2O = async () => {
const lead = await redirectToStoreFrontLead();
if (lead && useIndexStore().apiResult.storeFrontURL) {
    if (window.google_tag_manager && typeof window.dataLayer !== "undefined") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "eventTracking",
            action: "Redirect to Storefront",
            event_category: useUserStore().form.webSourceForm,
            event_label: useIndexStore().apiResult.districtID,
            value: 0,
            lead_type: useIndexStore().getLeadTypeFromServiceType(useIndexStore().form.serviceType,useIndexStore().form.categoryType),
            lead_category: useIndexStore().form.categoryType,
            lead_district: useIndexStore().apiResult.districtID,
            eventCallback: function () {
                window.location.href = `${useIndexStore().apiResult.storeFrontURL}?externalid=${useIndexStore().storefrontResult.user[1].quoteid}`;
            },
            eventTimeout: 2000,
        });
        setTimeout(function() {
            window.location.href = `${useIndexStore().apiResult.storeFrontURL}?externalid=${useIndexStore().storefrontResult.user[1].quoteid}`;
        }, 2000);
    } else {
        window.location.href = `${useIndexStore().apiResult.storeFrontURL}?externalid=${useIndexStore().storefrontResult.user[1].quoteid}`;
    }
}
};
const redirectToStoreFrontLead = async () => {
const campaignURL = JSON.parse(sessionStorage.getItem("campaignURL"));
let description = useUserStore().form.description;
if (campaignURL) {
    useIndexStore().utmSource = campaignURL["utmSource"];
    useIndexStore().utmCampaign = campaignURL["utmCampaign"];
    useIndexStore().utmMedium = campaignURL["utmMedium"];
    useIndexStore().utmTerm = campaignURL["utmTerm"]||campaignURL["etid"];
    useIndexStore().utmReferrer = campaignURL["referrer"];
    useIndexStore().gclid = campaignURL["gclid"];
    useIndexStore().fclid = campaignURL["fclid"];
    useIndexStore().promoCode = campaignURL["promoCode"];
}
if (useIndexStore().promoCode) {
    description = 'Promo Code: Spring Clean\n' + description;
}
try {
    return new Promise((resolve) => {
    axios
        .post(
        process.env.VUE_APP_RESTURL + "services/apexrest/LeadInformation",
        {
            leadExternalId: useUserStore().form.leadExternalId ? useUserStore().form.leadExternalId : "",
            company: useUserStore().form.businessName,
            streetAddress: useUserStore().form.street,
            cityAddress: useUserStore().form.city,
            stateAddress: useUserStore().form.state,
            zipAddress: useUserStore().form.zip,
            countryAddress: useUserStore().form.country,
            firstName: useUserStore().form.firstName,
            lastName: useUserStore().form.lastName,
            email: useUserStore().form.email?.toLowerCase(),
            phone: useUserStore().form.phone,
            district: useIndexStore().apiResult.districtID,
            webLeadServiceDetail: useIndexStore().form.serviceDetail ? useIndexStore().form.serviceDetail : useIndexStore().form.categoryType,
            description: description,
            leadSource: "Storefront",
            webSourceDetail: window.location.href.split("?")[0],
            completeWebSourceDetail: window.location.href,
            webSourceForm: useUserStore().form.webSourceForm,
            webLeadAction: useIndexStore().trackViewSplitter?'Request Quote Splitter':'',
            formStep: useUserStore().form.webLeadStep,
            serviceType: useIndexStore().getLeadTypeFromServiceType(useUserStore().form.serviceType, useUserStore().form.categoryType),
            servicesWanted: useUserStore().form.serviceForm.toString(),
            // servicesWantedSize: useUserStore().form.wantedSize,
            // servicesWantedFrequency: useUserStore().form.wantedFrequency,
            servicesWantedStartDate: useUserStore().form.wantedStartDate || useUserStore().form.wantedDeliveryDate,
            servicesWantedEndDate: useUserStore().form.wantedEndDate,
            servicesWantedQty: useUserStore().form.wantedQty || '',
            servicesWantedBusinessType: useUserStore().form.businessType,
            servicesWantedJSON: useUserStore().form.title ? '' : useUserStore().form.servicesWantedJSON == null ? '' : JSON.stringify(useUserStore().form.servicesWantedJSON),
            storeFrontStore: JSON.stringify(useIndexStore().storefrontResult.store?useIndexStore().storefrontResult.store:undefined),
            storeFrontUser: JSON.stringify(useIndexStore().storefrontResult.user?useIndexStore().storefrontResult.user:undefined),
            storeFrontVersion:'V2',
            gid: typeof window.parent.ga !== "undefined" && typeof window.parent.ga.getAll === 'function' ? window.parent.ga.getAll()[0].get("clientId") : "",
            utmSource: useIndexStore().utmSource,
            utmCampaign: useIndexStore().utmCampaign,
            utmMedium: useIndexStore().utmMedium,
            utmTerm: useIndexStore().utmTerm,
            utmReferrer: useIndexStore().utmReferrer,
            gclid: useIndexStore().gclid,
            fclid: useIndexStore().fclid,
            lang: useIndexStore().lang,
        })
        .then(function (response) {

        if (response.status === 200) {

            var data = response.data;
            var newStoreFrontUser = { quoteid: data,};
            useIndexStore().storefrontResult?.user?useIndexStore().storefrontResult.user.push(newStoreFrontUser):null;
            resolve(true);

        } else {

            useIndexStore().loading = false;
            useIndexStore().snackbar = true;
            resolve(false);

        }
        })
        .catch(function (error) {
        useIndexStore().loading = false;
        useIndexStore().snackbar = true;
        console.log(error);
        resolve(false);
        });
    });
    } catch (err) {
        console.error(err);
        useIndexStore().loading = false;
        return false;
    }
};
// Check available services for WC after entering address
const findAvailableServices = async ( callType, address, city, zip, latitude, longitude, line, district, lastName, firstName, email, phone, displayLanguage, state) => {
    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        // Set the startTime in the config metadata
        config.metadata = { startTime: new Date() }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Set the endTime in the response metadata
        response.config.metadata.endTime = new Date()
        // Calculate the duration by subtracting startTime from endTime
        response.duration = response.config.metadata.endTime - response.config.metadata.startTime
        return response;
    }, function (error) {
        error.config.metadata.endTime = new Date();
        error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
        return Promise.reject(error);
    });

    return new Promise((resolve,reject) => (axios
    .post(process.env.VUE_APP_RESTURL + "services/apexrest/ServiceProvidedSearch", {
        callType: callType?"servicefd":"service",
        serviceObject: {
            city: city,
            state: state,
            districtID: district===0?'':district,
            email: email,
            fname: firstName,
            lat: latitude,
            line: line,
            lname: lastName,
            lng: longitude,
            phone: phone,
            saddress: address,
            zip: zip,
            lang: displayLanguage,
        },
    }))
    .then(function (response) {
        useAxiosFormStore().leadAPILoadTime = response.duration;
        resolve(response);
        return response;
    })
    .catch(function (error) {
        useAxiosFormStore().leadAPILoadTime = error.duration;
        reject(error)
        return error
    }));
};

export default {sendInfoWithService,getTimeZoneForStore,createStoreDataWithFullData,createStoreData,redirectToStorefrontV1,redirectToStorefrontV2,sendDataToC2O,redirectToStoreFrontLead,findAvailableServices};